<template lang="pug">
div
  v-dialog.dialog(
    v-model='popupBoardSelection'
    max-width='630'
    overlay-color='base')
    template(v-slot:activator='{on}')
      v-btn.dialog__button(
        v-on='on'
        depressed
        @click='getAvailableTrelloBoards') {{ $lang.connectBoards }}
    v-card.backgroundMain(v-if='isLoadedAvailableTrelloBoards')
      template(
        v-if='availableTrelloBoards && Object.keys(availableTrelloBoards).length')
        v-card-title.dialog__title.d-flex.flex-nowrap
          span {{ $lang.boardsSelection }}
          v-btn.ml-2.ml-sm-auto.grey--text(
            text
            @click='popupBoardSelection = false'
            fab
            small)
            v-icon(dark) {{ mdiClose }}
        v-card-text.d-flex.flex-column
          v-autocomplete.dialog__select.dialog__select--workspace(
            v-model='selectedWorkspace'
            :items='userWorkspaces'
            :label='$lang.selectWorkspace'
            clearable
            hide-details)
          v-autocomplete.dialog__select.dialog__select--board(
            v-model='selectedBoards'
            :items='filteredBoardsToSelect'
            item-value='trello_id'
            item-text='name'
            :label='$lang.selectBoard'
            clearable
            hide-details
            multiple)
          div
            button.setting__button-white(@click='clearInputs') {{ $lang.cancel }}
            button.setting__button-blue(@click='connectedTrelloBoard') {{ $lang.apply }}
      template(v-else)
        v-card-title.dialog__title.d-flex.flex-nowrap
          span {{ $lang.noConnectionBoards }}
          v-btn.ml-2.ml-sm-auto.grey--text(
            text
            @click='popupBoardSelection = false'
            fab
            small)
            v-icon(dark) {{ mdiClose }}
    v-card.backgroundMain(v-else)
      .dialog__empty
        v-progress-circular(
          indeterminate
          color='primary')
</template>

<script>
import { mdiClose } from '@mdi/js'
import { createNamespacedHelpers, mapMutations } from 'vuex'
const { mapState: boardsMapState, mapActions: boardsMapActions } =
  createNamespacedHelpers('boards')

export default {
  name: 'BoardsConnectionDialog',
  data: () => ({
    mdiClose,
    popupBoardSelection: false,
    selectedWorkspace: null,
    selectedBoards: null,
  }),
  computed: {
    ...boardsMapState([
      'boards',
      'availableTrelloBoards',
      'isLoadedAvailableTrelloBoards',
    ]),
    userWorkspaces() {
      return this.availableTrelloBoards
        ? Object.keys(this.availableTrelloBoards)
        : null
    },
    boardsToSelect() {
      const arr = []
      if (this.availableTrelloBoards) {
        for (const key in this.availableTrelloBoards) {
          this.availableTrelloBoards[key].map((el) => {
            el['workspace'] = key
            el['disabled'] = this.boards.some((item) => item.id === el.id)
            arr.push(el)
          })
        }
      }
      return arr
    },
    filteredBoardsToSelect() {
      if (this.availableTrelloBoards) {
        if (this.selectedWorkspace) {
          return this.boardsToSelect.filter(
            (el) => el.workspace === this.selectedWorkspace
          )
        }
        return this.boardsToSelect
      }
      return []
    },
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...boardsMapActions([
      'connectAvailableTrelloBoards',
      'getAvailableTrelloBoards',
      'getBoards',
    ]),
    async connectedTrelloBoard() {
      const res = await this.connectAvailableTrelloBoards({
        boards_ids: this.selectedBoards,
      })

      if (
        res.length === this.selectedBoards.length &&
        res.every((el) => this.selectedBoards.includes(el.trello_id) && el.id)
      ) {
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.boardsSuccessConnected,
        })
        this.selectedWorkspace = null
        this.selectedBoards = null
        this.popupBoardSelection = false
        this.getBoards()
      } else if (!res.length) {
        this.ADD_NOTIFICATION({
          color: 'error',
          text: this.$lang.boardsErrorConnected,
        })
      }
    },
    clearInputs() {
      this.selectedWorkspace = null
      this.selectedBoards = null
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__button {
    @include btn-settings(false);
    text-transform: none;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;

    @media (max-width: 959px) {
      right: auto;
      left: 0;
      top: 23px;
    }
  }

  &__select {
    margin-bottom: 40px;
  }

  &__empty {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.setting__button-white {
  margin-right: 24px;

  @media (max-width: 959px) {
    margin-bottom: 12px;
  }
}
</style>

<style lang="scss">
.dialog__select--board.v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0deg) !important;
}

.v-list-item.v-list-item--disabled .v-icon__svg {
  fill: transparent !important;
}
</style>
